import './App.css';
import React, { useState } from 'react';
import data from './quotes_'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

function App() {

  const length = Object.keys(data['quote']).length

  let initialQuote = Math.floor(Math.random() * length)

  while (data['quote'][initialQuote] === undefined || data['quote'][initialQuote].length > 100) { initialQuote = Math.floor(Math.random() * length) }


  let [quote, setQuote] = useState(
    {
      quote: '“' + data['quote'][initialQuote].replace(/”|“|"/g, ' '),
      author: '- ' + data['author'][initialQuote],
      tweetLink: 'https://twitter.com/intent/tweet?text="'.concat(data['quote'][initialQuote].replace(/”|“|"/g, ' ').trim(), '" - ', data['author'][initialQuote])
    }
  );

  const [mode,dark] = useState(false);

  function changeMode()
  {
    const modeELem = document.getElementById('mode');
    const place = !mode?'flex-end':'flex-start' 
    setTimeout(()=>{
      modeELem.style.justifyContent = place
      document.body.style.background = mode?'radial-gradient(circle farthest-side at 2% 5%, rgb(255, 85, 0) 10% ,rgb(255, 234, 0) 20% ,rgb(138, 171, 255) 70%)':'radial-gradient(circle farthest-side at 2% 5%, rgb(235, 235, 235) 10% ,rgb(128, 128, 128) 20% ,rgb(0, 0, 0) 70%)'
      document.body.style.color = mode?'rgba(0,0,0,1)':'rgba(255,255,255,1)'
      document.getElementById('quote-box').style.boxShadow = !mode?'0em 0em 0em 0.05em white':'0em 0em 0em 0.05em black'
      document.getElementById('quote-box').style.backgroundColor = !mode?'rgba(0,0,0,1)':'rgba(255,255,255,1)'
      document.getElementById('link').style.borderColor = mode?'black':'white';
      document.getElementById('heading').style.borderColor = mode?'black':'white';
      document.getElementById('tweet-quote').style.color = mode?'black':'white';
      document.getElementById('new-quote').style.color = mode?'black':'white';
      dark(!mode)
    },300)
    
  }


  function generateQuote() {

    const randomIndex = Math.floor(Math.random() * length);
    document.getElementById('text').style.opacity = 0;
    document.getElementById('author').style.opacity = 0;
    document.getElementById('new-quote').style.cursor = 'wait'

    if (data['quote'][randomIndex] === undefined || data['quote'][randomIndex].length > 100) { generateQuote() }
    else {
      const newQuote = data['quote'][randomIndex].replace(/”|“|"/g, ' ');
      const newAuthor = data['author'][randomIndex];

      setTimeout(() => 
      {
        setQuote({
          quote: '“' + newQuote,
          author: '- ' + newAuthor,
          tweetLink: 'https://twitter.com/intent/tweet?text="'.concat(data['quote'][randomIndex].replace(/”|“|"/g, ' ').trim(), '" - ', data['author'][randomIndex]),
        });
        document.getElementById('text').style.opacity = 1
        document.getElementById('author').style.opacity = 1
        document.getElementById('new-quote').style.cursor = 'pointer'
      }, 500);

    }

  }


  return (<>
    <div onClick={changeMode} className='mode' id='mode'>{ mode===false?<FontAwesomeIcon icon={icon({name: 'sun', family: 'classic', style:'solid'})} size='2xl' color='rgba(255,64,64,1)' />:<FontAwesomeIcon icon={icon({name: 'moon', family: 'classic', style:'solid'})} size='2xl' color='rgba(255,255,255,1)' />}</div>
    <div className='d-flex align-items-center justify-content-center' id='quote-container'>
      <div id='quote-box' className="d-flex row">
        <div className='' id='heading'>Random Quote</div>
        <div id='text' className=""><span>{quote.quote}</span></div>
        <div id='author' className=""><a href={'https://www.google.com/search?q='+quote.author.slice(2,)} target='_blank' rel='noreferrer' style={{textDecoration:'none'}} >{quote.author}</a></div>
        <div id="link" className="d-flex row justify-content-between align-items-center">
          <div className='col-1'></div>
          <a href={quote.tweetLink} id='tweet-quote' target='_blank' rel="noreferrer" className="col-4 btn"><FontAwesomeIcon icon={icon({name: 'twitter', family: 'classic', style: 'brands'})} shake /></a>
          <div className='col-2'></div>
          <div className="col-4 btn" id='new-quote' onClick={generateQuote}><i className="fa-solid fa-shuffle" /></div>
          <div className='col-1'></div>
        </div>
      </div>
    </div>
    <div id='footer'>&copy; kulashri.dev |&nbsp;<a href='https://q.kulashri.dev' target='_blank' rel='noreferrer'>Random Quotes</a>&nbsp;| by Ankush Kulashri</div>
  </>
  )

}

export default App;